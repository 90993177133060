/* expense display styles*/
.expense-data-container {
  margin-top: 40px;
}

.expense-display-div {
  display: inline-block;
  margin: 0px 20px 20px 20px;
}

.expense-display-card {
  width: 10rem !important;
  height: 15rem !important;
  border: none !important;
  box-shadow: 5px 5px 5px #dddbdb;
  border: 2px solid #6639a6 !important;
}

.card-expense-title {
  font-size: x-large !important;
  font-weight: bold !important;
  color: red !important;
  margin-bottom: 20px !important;
}

.btn-delete:hover {
  cursor: pointer;
  fill: lightgray;
}

/* income display styles*/

.card-income-title {
  font-size: x-large !important;
  font-weight: bold !important;
  color: green !important;
  margin-bottom: 20px !important;
}

.card-date {
  font-size: small !important;
}

.card-type {
  font-style: italic;
}

.card-notes {
  height: 65px !important;
}

.card-footer {
  background-color: #6639a6 !important;
  color: white !important;
}

/* media queries */

@media only screen and (max-width: 430px) {
  .expense-display-card {
    width: 8rem !important;
  }
}
