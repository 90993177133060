.add-button {
  width: 80px;
  height: 80px;
  background-color: #6639a6 !important;
  border-color: #6639a6 !important;
  margin-top: 20px;
}

.add-button:hover {
  background-color: rgb(201, 87, 201) !important;
  border-color: rgb(201, 87, 201) !important;
}

.form-item {
  display: inline-block;
  margin: 0px 20px 0px 20px;
}

.form-inputs {
  margin-bottom: 20px;
}

.form-offcanvas {
  background-color: aliceblue !important;
}

.input-add-button {
  background-color: #6639a6 !important;
  border-color: #6639a6 !important;
}

.input-add-button:hover {
  background-color: rgb(201, 87, 201) !important;
  border-color: rgb(201, 87, 201) !important;
}