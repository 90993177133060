.pie-chart {
  margin-left: 100px;
}
/* Media queries for pie chart */
@media only screen and (max-width: 430px) {
  .pie-chart {
    margin-left: 40px;
  }
}

@media only screen and (max-width: 820px) {
  .pie-chart {
    margin-left: 20px;
  }
}